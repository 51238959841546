<template>
    <v-container fluid style="padding-bottom: 50px;">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-card-text class="p-2">
                        <v-container fluid>
                            <v-row>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building-outline" clearable dense solo v-model="office_id" :items="offices" item-value="office_id"
                                        :item-text="item => item.office.trim()" label="Office" class="ma-0 pa-0 border-12" hide-details=true :disabled="office_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-menu ref="modal" v-model="modal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field dense solo v-model="dt_rcv_Start" label="Start Date Receive" prepend-inner-icon="mdi-calendar" clearable readonly class="ma-0 pa-0 border-12" hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="dt_rcv_Start" no-title @input="modal = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-menu ref="modal_to" v-model="modal_to" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">   
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense solo v-model="dt_rcv_End" label="End Date Receive" prepend-inner-icon="mdi-calendar" clearable readonly v-bind="attrs" v-on="on" class="ma-0 pa-0 border-12" hide-details=true></v-text-field>
                                        </template>
                                    <v-date-picker v-model="dt_rcv_End" no-title @input="modal_to = false"></v-date-picker>
                                        </v-menu>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete 
                                        prepend-inner-icon="mdi-truck-fast" clearable dense solo v-model="vendor_id" :items="vendors" item-title="vendor_name" item-value="vendor_id" :item-text="item => item.vendor_name.trim()" 
                                        label="Vendor" class="ma-0 pa-0 border-12" hide-details=true></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete 
                                        prepend-inner-icon="mdi-list-status" clearable dense solo v-model="statLPBtoPP" :items="LPBtoPP" item-value="value" :item-text="item => item.text" 
                                        label="Status LPB to PP" class="ma-0 pa-0 border-12" hide-details=true></v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="6">
                                    <v-btn class="border-12 mr-2"  style="padding: 20px;"
                                    elevation="2" small @click="clear()">Clear</v-btn>
                                    <v-btn class="border-12" style="padding: 20px;"
                                    color="info" elevation="2" small @click="searchFunction()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-12">
            <v-col class="col-12" sm="12" md="12">
                <v-card outlined class="p-0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="headers"
                                    class="elevation-1"
                                    :items="histories"
                                    :items-per-page="30"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    :loading="$store.state.overlay"
                                    height="600"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="searchItemTrans" 
                                    :item-class="tr_datatable"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Report LPB to PP</h6>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                <v-btn
                                                small
                                                color="#005c37"
                                                class="py-5 mr-3 text-white border-12"
                                                >
                                                    <v-icon>mdi-file-excel</v-icon>
                                                    <download-excel
                                                        class="text"
                                                        :fetch           = "exportExcel"
                                                        :data="histori_exports"
                                                        :before-generate = "startDownload"
                                                        :before-finish   = "finishDownload">
                                                        Export Excel
                                                    </download-excel>
                                                </v-btn>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:item.lpb_amt="{ item }">
                                        <span>{{ item.lpb_amt | formatRupiah }}</span>
                                    </template>
                                    <template v-slot:item.bph_amt="{ item }">
                                        <span>{{ item.bph_amt | formatRupiah }}</span>
                                    </template>
                                    <template v-slot:item.pay_amt="{ item }">
                                        <span>{{ item.pay_amt | formatRupiah }}</span>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            modal: false,
            loading: false,
            // date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
            searchItemTrans: '',
            division: 'all',
            sloworfast: [
                'C', 'R', 'O'
            ],
            ketstok: [
                'G', 'R', 
            ],
            division_disabled: false,
            histories: [],
            office_id: '',
            FASTorSLOW: '',
            KetStok: '',
            vendor_id: '',
            tr_type: '',
            dt_rcv_Start: '' , 
            dt_rcv_End: '',
            LPBtoPP: [
                'Y', 'N'
            ],
            statLPBtoPP: '',
            PRStat: '',
            com_id: '',
            dt_req: '',
            cat_id: '',
            offices: [],
            offices: [],
            cat_group: '',
            cat_desc: '',
            category_id: '',
            categorygroups: [],
            categories: [],
            commodities: [],
            loading_office: false,
            office_disabled: false,
            office: '',
            date_from: '',
            PRs: [],
            TRs: [],
            vendors: [],
            loading: false,
            modal: false,
            date_to: '',
            modal_to: false,
            histori_exports: [],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                    text: 'SMS',
                    disabled: false,
                    href: '/admin/sms',
                },
                {
                    text: 'Purchasing',
                    disabled: true,
                },
                {
                    text: 'Report LPB to PP',
                    disabled: true,
                }
            ],
            headers: [
                { text: 'Office', value: 'office_id', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'Purchase Receive ID', value: 'purc_rcv_id', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'Receive Date', value: 'dt_rcv', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'No Surat Jalan', value: 'sj_no', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'Nama Vendor', value: 'vendor_name', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'LPB Amount', value: 'lpb_amt', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'No BPH', value: 'bph_no', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'Ext Inv', value: 'ext_inv', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'BPH Amount', value: 'bph_amt', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'Pay ID', value: 'pay_id', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'Pay Amount', value: 'pay_amt', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'Pay Date', value: 'dt_pay', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'AC Num', value: 'ac_num', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'AC Name', value: 'ac_name', align: 'center', class: 'primary--text blue lighten-5' },
            ],
        }
    },
    filters: {
        twoDecimals(value) {
            if (!value) return '';
            return parseFloat(value).toFixed(2);
        },
        oneDecimals(value) {
            if (!value) return '';
            return parseFloat(value).toFixed(1);
        },
        noDecimals(value) {
            if (!value) return '';
            return parseFloat(value).toFixed(0);
        },
        formatRupiah(value) {
            if (!value) return '';
            return 'Rp ' + parseFloat(value).toLocaleString('id-ID');
        },
    },
    computed:{
        
    },
    watch: {
        
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        // await this.getEnvConf()
        await this.getOffice()
        // await this.getCategory()
        // await this.getCommodity()
        // await this.getPR()
        // await this.getTR()
        await this.getVendor()

        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        tr_datatable(item) {
            var rowClass = 'tr_datatable_history'
            return rowClass;
        },
        // async getEnvConf(){

        //     const respDataOffice = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=STARCON&appl_id=WEBSTARCON&var_id=OFFICE_ID&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false)  
        //     if (respDataOffice.status == 200) {
        //         this.office_id = respDataOffice.data.data ? respDataOffice.data.data : ''
        //         this.office_disabled = respDataOffice.data.data ? true : false
        //         if (this.office_disabled) {
        //             await this.getOffice()
        //         } else {
        //             await this.getOffice('KMB')
        //         }
        //     }
        //     console.log(this.office_id)
        //     await this.getOffice()
        // },
        clear(){
            this.statLPBtoPP = ''
            this.vendor_id = ''
            this.office_id = ''
            this.dt_rcv_Start = ''
            this.dt_rcv_End = ''
            // this.dt_req = ''
        },
        getOffice(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/starcon/office`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data
            });
        },
        async getVendor(){
            await axios.post(`${process.env.VUE_APP_URL}/api/master/starcon/showVendor`, {
                vendor_id: this.vendor_id,
                vendor_name: this.vendor_name,
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                }
            }).then(res => {
                this.vendors = res.data
            });
        },
        async exportExcel(){

            if (this.dt_rcv_Start === '' || this.dt_rcv_End === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please Fill Start Date & End Date Receive',
                    visible: true
                };

                this.$store.dispatch('setOverlay', false)  
                return false
            }

            var reqBody = {
                'office_id': this.office_id ? this.office_id : '',
                'statLPBtoPP': this.statLPBtoPP ? this.statLPBtoPP : '',
                'vendor_id': this.vendor_id ? this.vendor_id : '',
                'dt_rcv_Start': this.dt_rcv_Start ? this.dt_rcv_Start : '',
                'dt_rcv_End': this.dt_rcv_End ? this.dt_rcv_End : '',
            }

            const respData = await backendApi.fetchCore('/api/starcon/LPBtoPP', reqBody, false, false, false);
            if (respData.status === 200) {
                console.log("API Response Data:", respData.data); 
                if (Array.isArray(respData.data)) {
                    this.histori_exports = respData.data
                    return this.histori_exports
                } else {
                    console.error("Expected an array but got:", typeof respData.data);
                    this.histories = []; 
                }
                console.log("Histories:", this.histories); 
            }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
        async searchFunction(){
            this.$store.dispatch('setOverlay', true)  

            if (this.dt_rcv_Start === '' || this.dt_rcv_End === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please Fill Start Date & End Date Receive',
                    visible: true
                };

                this.$store.dispatch('setOverlay', false)  
                return false
            }
            
            var reqBody = {
                'office_id': this.office_id ? this.office_id : '',
                'statLPBtoPP': this.statLPBtoPP ? this.statLPBtoPP : '',
                'vendor_id': this.vendor_id ? this.vendor_id : '',
                'dt_rcv_Start': this.dt_rcv_Start ? this.dt_rcv_Start : '',
                'dt_rcv_End': this.dt_rcv_End ? this.dt_rcv_End : '',
            }

            const respData = await backendApi.fetchCore('/api/starcon/LPBtoPP', reqBody, false, false, false);
            if (respData.status === 200) {
                console.log("API Response Data:", respData.data); 
                if (Array.isArray(respData.data)) {
                    this.histories = respData.data; 
                } else {
                    console.error("Expected an array but got:", typeof respData.data);
                    this.histories = []; 
                }
                console.log("Histories:", this.histories); 
                this.$store.dispatch('setOverlay', false);
            }
        },
    },    
}
</script>